.purchaseOrders {
  &-header {
    &-controls {
      a {
        color: #131313;
        display: flex;
        white-space: nowrap;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        margin-right: 1.2rem;
        border-bottom: 2px solid transparent;
        svg {
          margin-right: .3rem;
        }
        &:hover {
          border-bottom: 2px solid #131313;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  &-body {
    &-accordion {
      &-header {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}