@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background-color: white;
}

a {
  color: #51ad32;
  &:hover {
    color: #51ad32;
  }
}

p {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

.nav-item {
  .active {
    background: linear-gradient(to right, #0e4f2d 1%, #51ad32 50%, #a1c517 100%) !important;
  }
}

.cursor {
  cursor: pointer;
}

.card-header-tabs {
  margin: 0 !important;
}

.section-number {
  color: #418c28;
  font-weight: bold;
}

.button-green {
  background: linear-gradient(to right, #0e4f2d 1%, #51ad32 50%, #a1c517 100%);
  width: 100%;
  outline: none !important;
  border: none !important;
  height: auto;
  white-space: nowrap;
  color: white !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-weight: 600;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  img {
    height: 20px;
    width: 20px;
  }
  &--search {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

.width-fit {
  width: fit-content;
}

text {
  font-family: 'Roboto', sans-serif !important;
  fill: #131313 !important;
  font-weight: 600;
}

table {
  margin-bottom: 0 !important;
}

thead tr {
  font-weight: 600;
  cursor: auto;
  th {
    background-color: rgb(255,255,255) !important;
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6 !important;
    font-size: 16px !important;
  }
}

tbody tr {
  &:nth-child(odd) {
    background-color: #f1f1f1 !important;
  }
  &:hover {
    background-color: #e3e3e3 !important;
  }
}

td {
  color: #131313;
  border: 1px solid #dee2e6;
  font-size: 16px !important;
}

hr {
  margin-top: .5rem !important;
}

.tag {
  padding: .3rem .5rem;
  width: fit-content;
  color: white;
  font-weight: bold;
  &--green {
    background-color: #51ad32;
  }
  &--orange {
    background-color: #ee8d43;
  }
}

.plain-link {
  font-weight: normal;
  color: #131313;
  text-decoration: none;
  &--bold {
    font-weight: bold;
  }
  &:hover {
    text-decoration: none;
    color: #131313;
  }
}

.modal-title {
  font-weight: bold !important;
}

label {
  margin-bottom: .2rem;
  font-weight: bold;
}

.dialog-width {
  max-width: 1200px;
}

.w-15 {
  width: 15%;
}

.form-check-label {
  font-weight: normal;
}

.large-dialog {
  max-width: 1500px;
}

.extra-large-dialog {
  max-width: 1800px;
}

.w-35 {
  width: 35%;
}

@media print {
  .hide-on-print {
    display: none !important;
  }
}

.tab-border {
  border: 1px solid #dee2e6;
}

.form-label {
  font-weight: bold;
}

.card-header {
  background-color: #F7F7F7 !important;
}

.card-header--sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.green-section-header {
  color: #418c28;
  font-weight: bold;
}

.black-text {
  color: black;
}

.small-qty {
  width: 100px;
}

.small-cell {
  width: 200px;
}

.mid-cell {
  width: 300px;
}


.mr-custom {
  margin-right: 8rem;
}

@media only screen and (max-width: 1280px) {
  .mobile-row {
    display: flex;
    flex-direction: column;
  }
  .mobile-column {
    padding: 0 !important;
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 850px) {
  .card-body {
    padding: .5rem;
  }
}

.tbl-qty-w1 {
  width: 1rem;
}

.tbl-qty-w2 {
  width: 3rem;
}

.tbl-qty-w3 {
  width: 6rem;
}

.tbl-qty-w4 {
  width: 9rem;
}

.mx {
  max-width: fit-content;
}

.notes-list {
  list-style-type: disc; /* This is the default bullet point style */
  padding-left: 20px;    /* Adjust padding to your preference */
}

.notes-list li {
  margin-bottom: 5px;    /* Add space between list items */
}

/* Custom styling for the introduction section */
.introduction-section {
  font-family: Arial, sans-serif;
  margin-bottom: 1.5rem;
}

.introduction-section h5 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.introduction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0.5rem;
  border: 1px solid #000;
}

.introduction-table th, .introduction-table td {
  border: 1px solid #000;
  padding: 10px 15px;
  font-size: 0.9rem;
}

.introduction-table th {
  font-weight: bold;
  text-align: left;
  background-color: #f0f0f0;
}

.introduction-table td {
  background-color: #f9f9f9;
}

.introduction-note {
  font-size: 0.85rem;
  margin-top: 0.5rem;
  color: #333;
  line-height: 1.5;
}

.doc-section {
  border: 1px solid black;
  margin-bottom: 1rem;
  &-title {
    border-bottom: 1px solid black;
    padding: 0.75rem;
    h4 {
      font-weight: bold;
      margin: 0;
    }
  }
  &-content {
    th {
      border: 1px solid black !important;
      border-top: none !important;
      border-right: none !important;
      &:first-of-type {
        border-left: none !important;
      }
    }
    td {
      border: 1px solid black ;
      border-top: none;
      border-right: none !important;
      background-color: white !important;
      &:first-of-type {
        border-left: none !important;
      }
    }
    &-padding {
      padding: 0.75rem;
    }
  }
}

.no-border {
  border-left: none !important;
}

.footer {
  display: none;

  &__green {
    font-weight: bold;
    color: #116339;
  }
  &__small {
    font-size: 10px;
  }
}

@media print {
  .footer {
    display: flex !important;
    position: fixed;
    bottom: 0;

  
    &__green {
      font-weight: bold;
      color: #116339;
    }
    &__small {
      font-size: 10px;
    }
  }

  .doc-section {
    page-break-inside: avoid;
    margin-bottom: 20px;
  }


  @page {
    margin: 20mm 10mm 20mm 10mm;
}
}

.noBottomBorder {
  border-bottom: none !important;
}

.borderTop {
  border-top: 1px solid black !important;
}