.screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &-top {
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    //background: linear-gradient(to right, #0e4f2d 1%, #51ad32 50%, #a1c517 100%);
    background-color: #131313;

    &-search {
      width: 25rem;

      .form-control {
        height: 2rem !important;
      }

      .input-group-text {
        height: 2rem !important;
        background: linear-gradient(to right, #0e4f2d 1%, #51ad32 50%, #a1c517 100%);
        border: none;
      }
    }

    &-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: white;

      &-extra {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        align-items: center;
        padding-right: 2.5rem;

        svg {
          margin-right: .5rem;
          cursor: pointer;
        }

        &-message {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          svg {
            margin-right: .5rem;
          }

          &-content {
            display: flex;
            flex-direction: column;

            &__user {
              font-weight: 600;
            }

          }
        }
        &-notification {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          svg {
            margin-right: .5rem;
          }
          &__title {

          }
        }
        &-help {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          svg {
            margin-right: .5rem;
          }
          &__title {

          }
        }
      }

      svg {
        margin-left: .5rem;
      }
    }

    &__logo {
      height: 100%;
      width: auto;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    background-color: #F4F4F4;
    width: 100%;
    height: calc(100vh - 4rem);

    &-side {
      width: 18rem;
      display: flex;
      position: relative;
      background-color: #262626;
      height: 100%;
      flex-direction: column;
      box-sizing: border-box;
      &-collapsed {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 5rem;
        align-items: center;
        height: 100%;
        background-color: #262626;
        &-open {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: #181818;
          width: 100%;
        }
        &-item {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem 0;
          box-sizing: border-box;
          &--active {
            background: linear-gradient(to right, #0e4f2d 1%, #51ad32 50%, #a1c517 100%);
          }
          &:hover {
            background-color: #131313;
          }
        }
      }

      &-user {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        width: 100%;
        background-color: #181818;

        &__image {
          background: linear-gradient(to right, #0e4f2d 1%, #51ad32 50%, #a1c517 100%);
          border-radius: 50px;
          padding: .5rem;
          margin-bottom: .5rem;
          box-sizing: border-box;
        }

        &__name {
          font-size: 18px;
          font-weight: 600;
          color: white;
        }

        &-info {
          width: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          margin-top: 1rem;

          &__text {
            display: flex;
            flex-direction: row;
            margin-bottom: .8rem;
            align-items: center;
            font-size: 14px;
            color: white;

            &:last-of-type {
              margin: 0;
            }

            svg {
              margin-right: .5rem;
            }
          }
        }
        
      }

      &-toggle {
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
        color: white;
        padding: 1rem;
        display: flex;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        height: 4rem;
        bottom: 0;
        background-color: #181818;
        left: 0;
      }

      &-links {
        width: 100%;

        &__link {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 18px;
          font-weight: 300;
          color: white;
          position: relative;
          padding: 1rem;
          cursor: pointer;
          transition: .3s;
          text-decoration: none;

          &--dropdown {
            text-decoration: none;
            color: white;
            background-color: #131313;
          }

          &--chevron {
            position: absolute;
            right: 0;
          }

          &--sub {
            background-color: #1c1c1c;
            padding-left: 2rem !important;
          }

          &--active {
            font-weight: 600;
            background: linear-gradient(to right, #0e4f2d 1%, #51ad32 50%, #a1c517 100%);
          }

          svg {
            margin-right: .5rem;
          }

          &:hover {
            text-decoration: none;
            color: white;
            background-color: #131313;
          }
        }
      }
    }

    &-main {
      box-sizing: border-box;
      width: 100%;
      height: calc(100vh - 4rem);
    overflow: hidden;
      &--padding {
        padding: 2rem;
      }
      &--scroll {
        overflow-y: auto;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .screen-content-side {
    width: fit-content;
  }
  .screen-content-side-user {
    display: none;
  }
  .screen-content-side-links__link svg{
    margin: 0;
  }
}

@media only screen and (max-width: 850px) {
  .screen-content-main--padding {
    padding: .5rem;
  }
}