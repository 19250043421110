.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: url('https://www.thercoserckhtx.com/wp-content/uploads/2018/03/home_image.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  &-form {
    width: 25rem;
    padding: 2rem 2rem 2rem 2rem;
    box-sizing: border-box;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    border-radius: 5px;
    position: relative;
    z-index: 1;
    background: inherit;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      background: inherit;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
      filter: blur(10px);
      margin: -20px;
    }
    &__input {
      margin-bottom: .5rem;
      font-family: 'Roboto', sans-serif;
      &:first-of-type {
        margin-top: 1rem;
      }
      &:focus {
        border-color: #51ad32 !important;
        box-shadow: 0 0 0 0.2rem rgba(55, 255, 0, 0.1);
      }
    }
    &__logo {
      width: 100%;
      margin-bottom: 1rem;
    }
    &-check {
      margin-bottom: 1rem;
      display: flex;
      &__label {
        padding-bottom: .2rem;
        font-size: 14px;
        color: white;
      }
    }
  }
}

.login-form-check .login-form-check__box:checked ~ .login-form-check__label::before {
  background-color: green!important;
}

.login-form-check .login-form-check__box:checked:focus ~ .login-form-check__label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25) !important;
}
.login-form-check .login-form-check__box:focus ~ .login-form-check__label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25) !important;
}
.login-form-check .login-form-check__box:active ~ .login-form-check__label::before {
  background-color: #C8FFC8 !important;
}