.trafficLights {
  display: flex;
  flex-direction: row;
  &-box {
    padding: 1rem;
    border: 1.4px solid;
    margin-right: .3rem;
    border-radius: .25rem;
    cursor: pointer;
    &--green{
      border-color: #51ad32;
      background: rgba(81, 173, 50, 0);
      &--no-hover {
        &:hover {
          cursor: auto;
          background: rgba(81, 173, 50, 0) !important;
        }
      }
      &:hover {
        background: rgba(81, 173, 50, 0.3);
      }
      &--active {
        background: rgba(81, 173, 50, 1);
        &:hover {
          background: rgba(81, 173, 50, 1) !important;
        }
      }
    }
    &--amber{
      border-color: #ee8d43;
      background: rgba(238, 141, 67, 0);
      &--no-hover {
        &:hover {
          cursor: auto;
          background: rgba(238, 141, 67, 0) !important;
        }
      }
      &:hover {
        background: rgba(238, 141, 67, 0.3);
      }
      &--active {
        background: rgba(238, 141, 67, 1);
        &:hover {
          background: rgba(238, 141, 67, 1) !important;
        }
      }
    }
    &--red{
      border-color: #d94343;
      background: rgba(217, 67, 67, 0);
      &--no-hover {
        &:hover {
          cursor: auto;
          background: rgba(217, 67, 67, 0) !important;
        }
      }
      &:hover {
        background: rgba(217, 67, 67, 0.3);
      }
      &--active {
        background: rgba(217, 67, 67, 1);
        &:hover {
          background: rgba(217, 67, 67, 1) !important;
        }
      }
    }
  }
}