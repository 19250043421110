.purchaseOrdersCreate {
  &-template {
    &__date {
      width: 100%;
      input[type=text] {
        width: 100%;
      }
    }
    &__green-text {
      color: #418c28;
    }
    background-color: white;
    padding: 4rem 3rem;
    width: 100%;
    &-supplier {
      width: 40rem;
    }
    &-notes-list {
      list-style-type: disc;
      li {
        margin-left: 0.8rem;
      }
      &__button {
        width: fit-content;
        white-space: nowrap;
      }
    }
    &__textbox {
      width: 30rem;
    }
    &__button {
      width: auto;
      padding: .5rem 2rem;
    }
    &-signatures {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 2rem;
    }
  }
}

@media print {
  .PO-printing {
    display: none !important;
  }
}