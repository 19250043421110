.dashboard {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 2rem;
  height: 100%;
  grid-auto-rows: minmax(100px, auto);
  &-space {
    &__single-value {
      font-size: 42px;
    }
    &-table {
      position: absolute;
      border: 0;
      margin: 0;
      height: 100%;
      border-collapse: unset;
      padding: 1.25rem;
    }
    &--one {
      grid-column: 1 / 4;
      grid-row: 1;
    }
    &--two {
      grid-column: 4 / 7;
      grid-row: 1;
    }
    &--three {
      grid-column: 7 / 10;
      grid-row: 1;
    }
    &--four {
      grid-column: 1 / 6;
      grid-row: 2;
    }
    &--five {
      grid-column: 6 / 8;
      grid-row: 2;
    }
    &--six {
      grid-column: 8 / 10;
      grid-row: 2;
    }
    &--seven {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--eight {
      grid-column: 3 / 5;
      grid-row: 3;
    }
    &--nine {
      grid-column: 5 / 10;
      grid-row: 3;
    }
  }
}

@media only screen and (max-width: 850px) {
  .dashboard {
    gap: 15rem;
    grid-template-columns: repeat(1, 1fr);
    &-space {
      height: 20rem;
      &--one {
        grid-column: 1;
        grid-row: 1;
      }
      &--two {
        grid-column: 1;
        grid-row: 2;
      }
      &--three {
        grid-column: 1;
        grid-row: 3;
      }
      &--four {
        grid-column: 1;
        grid-row: 4;
      }
      &--five {
        grid-column: 1;
        grid-row: 5;
      }
      &--six {
        grid-column: 1;
        grid-row: 6;
      }
    }
  }
}

tr {
  cursor: pointer;
}