.datasheet {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 2rem;
  width: 100%;
  grid-auto-rows: minmax(100px, auto);

  &-list {
    grid-column: 1 / 8;
    grid-row: 1;

    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__search {
        width: 25rem;
        background-color: white;
        background-image: url('../Images/icons8-search-96.png');
        background-position: 10px 9px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        padding-left: 40px;
      }
      &-controls {
        display: flex;
        flex-direction: row;
        a {
          color: #131313;
          display: flex;
          white-space: nowrap;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          cursor: pointer;
          margin-right: 1.2rem;
          border-bottom: 2px solid transparent;
          svg {
            margin-right: .3rem;
          }
          &:hover {
            border-bottom: 2px solid #131313;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    &--full {
      grid-column: 1 / 10;
    }

    &-table {
      width: 100%;
      &-head {
        th {
          position: relative;
          svg {
            position: absolute;
            right: .75rem;
            cursor: pointer;
          }
        }
      }
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }

  &-form {
    grid-column: 8 / 10;
    grid-row: 1;
    position: sticky;
    top: 0;
    height: fit-content;
    &-edit {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      &__button {
        display: flex;
        font-weight: 600;
        color: #131313;
        text-decoration: none;
        flex-direction: row;
        margin-top: 1rem;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        svg {
          margin-right: .3rem;
        }
        &:hover {
          border-bottom: 2px solid #131313;
          text-decoration: none;
          color: #131313;
        }
      }
    }
  }
}